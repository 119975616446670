import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Style from './Styles/ImageContainerStyle';
import Settings from '../../Settings/Settings'
import CreateIcon from '@material-ui/icons/Create';
import Moment from 'react-moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'moment/dist/locale/ro';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import DeleteImageDialog from './DeleteImageDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import AddToCartButton from './AddToCartButton'
import CardActionArea from '@material-ui/core/CardActionArea';
import LinkIcon from '@material-ui/icons/Link';
import ImageAlbums from './ImageAlbums';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Chip from '@material-ui/core/Chip';
import { subscribe, unsubscribe } from '../../Events/Events';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import SendIcon from '@material-ui/icons/Send';
import PhotographersAvatar from '../HomeComponent/MaterialComponents/PhotographersAvatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import ExifPopover from './ExifPopover';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function RawImageContainer(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [myPictureLocation, setMyPictureLocation] = React.useState(props.element.imagesLocationName)
  const [myPictureDescription, setMyPictureDescription] = React.useState(props.element.imagesDescription)
  const [myPicturePublished, setMyPicturePublished] = React.useState(parseInt(props.element.imagesPublished))
  const [initialMyPictureLocation, setInitialMyPictureLocation] = React.useState(props.element.imagesLocationName)
  const [initialMyPictureDescription, setInitialMyPictureDescription] = React.useState(props.element.imagesDescription)
  const [initialMyPicturePublished, setInitialMyPicturePublished] = React.useState(parseInt(props.element.imagesPublished))
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [showImageLoader, setShowImageLoader] = React.useState(true)
  useEffect(() => {
    let img = document.createElement('img');
    img.src = Settings.API_URL + Settings.API_ENDPOINT_FILE + props.element.imagesPath+ '?thumbsWidth=800';
  }, [])
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const getPictureUrl = (imagePath) => {
    if(window.location.pathname === '/') {
      return '/image/' + imagePath
    } else {
      let concatString = '/'
      if (window.location.pathname.slice(-1) === '/') {
        concatString = ''
      }
      return window.location.pathname + concatString + 'image/' + imagePath
    }
  }
  const setInitialValues = () => {
    setMyPictureLocation(initialMyPictureLocation)
    setMyPictureDescription(initialMyPictureDescription)
    setMyPicturePublished(initialMyPicturePublished)
  }
  const updateInitialValuesWithMyPicture = () => {
    setInitialMyPictureDescription(myPictureDescription)
    setInitialMyPictureLocation(myPictureLocation)
    setInitialMyPicturePublished(myPicturePublished)
  }

  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const saveImage = () => {
    const data = {
        imagesDescription: myPictureDescription,
        imagesLocationName: myPictureLocation,
        imagesPublished: myPicturePublished === false ? 0 : 1
    }
    updateInitialValuesWithMyPicture();
    componentMethods.updateImage(data, props.element.imagesID)
  }

  return <Grid item xs={12} lg={3} md={3} id={'product-' + props.element.imagesID}>
            <div className={classes.rootImage}>
                <Paper>
            <DeleteImageDialog imageID={props.element.imagesID} componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} setOpenDeleteDialog={setOpenDeleteDialog} openDeleteDialog={openDeleteDialog} />
                  <div className={showImageLoader ? classes.imageContainer : null}>
                    {showImageLoader ?
                    <LoadingComponent style={{position: "absolute", margin: "0 auto", top: 125, zIndex: 100, display: "block"}}/>
                    :
                    null
                    }
                  <img onLoad={() => {setShowImageLoader(false)}} className={classes.image} src={Settings.API_URL + Settings.API_ENDPOINT_PATH_RAW_THUMB + '?path=' + props.element.imagesPath + '.' + props.element.imagesExtension + '.png'} alt={props.componentConstants.language.LANG_WEBSITE_MOTO + props.componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + props.element.usersFirstName + " " + props.element.usersLastName + " "+props.element.imagesLocationName + " " + props.element.imagesDescription} />
                  </div>
                <div style={{margin: 8}}>
                  <div style={{display:"inline-block"}}>
                    <Link to={"/user/pictures/" + props.element.imagesUsersID} ><PhotographersAvatar usersID={props.element.imagesUsersID} el={props.element} componentMethods={componentMethods} componentConstants={componentConstants} componentProps={componentProps} /></Link>
                  </div>
                </div>
                { props.canEdit ?
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <CreateIcon />
                  </IconButton>
                  :
                  null
                  }
                  { props.canEdit ?
                    <a href={Settings.API_URL + Settings.API_ENDPOINT_DOWNLOAD_RAW_IMAGE + '?path=' + props.element.imagesPath + '.' + props.element.imagesExtension + '&accountToken='  + componentMethods.getLocalStorage("user").tokensHash}>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded,
                        })}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </a>
                    :
                    null
                    }
                  <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapse}>
                    <Typography paragraph>
                    <TextField fullWidth={true} id="standard-basic" label={props.componentConstants.language["LANG_UPLOAD_IMAGE_LOCATION_NAME"]} value={myPictureLocation} onChange={(e) => {setMyPictureLocation(e.target.value)}} />
                    </Typography>
                    <Typography paragraph>
                    <TextField fullWidth={true} id="standard-basic" label={props.componentConstants.language["LANG_UPLOAD_IMAGE_DESCRIPTION"]} value={myPictureDescription} onChange={(e) => {setMyPictureDescription(e.target.value)}} />
                    </Typography>
                    <Button fullWidth={true} variant="contained" color="secondary" className={classes.buttonSave} onClick={saveImage}>
                        {props.componentConstants.language.LANG_SAVE}
                    </Button>
                    <Button fullWidth={true} variant="outlined" color="secondary" className={classes.buttonCancel} onClick={setInitialValues}>
                        {props.componentConstants.language.LANG_CANCEL}
                    </Button>
                    <Button fullWidth={true} variant="contained" color="primary" className={classes.buttonDelete} onClick={(e)=>{setOpenDeleteDialog(true)}} startIcon={<DeleteIcon />}>
                        {props.componentConstants.language.LANG_DELETE}
                    </Button>
                </Collapse>
            </Paper>
            </div>
          </Grid>
}