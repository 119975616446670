import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Settings from '../../../Settings/Settings'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Style from '../Styles/SearchYourImagesStyle';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import $ from "jquery";
import LoadingComponent from '../../LoadingComponent/LoadingComponent';
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Icon, Paper } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function AddImage(props) {
    const classes = useStyles();
    const [imagesArray, setImagesArray] = React.useState([])
    const [filesArray, setFilesArray] = React.useState([])
    const [uploadingImages, setUploadingImages] = React.useState(false)
    const [locationNames, setLocationNames] = React.useState([]);
    const [descriptions, setDescriptions] = React.useState([])
    const [uploadingFileIndex, setUploadingFileIndex] = React.useState(null)
    const [albumsArray, setAlbumsArray] = React.useState({})
    const [filesUploaded, setFilesUploaded] = React.useState([])
    const[filesFailed, setFilesFailed] = React.useState([])
    const [seed, setSeed] = React.useState(1);

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
        addImageToAlbum : (albumsID, index) => {
            addImageToAlbum(albumsID, index)
          },
          deleteImageFromAlbum : (albumIndex, index) => {
            deleteImageFromAlbum(albumIndex, index)
          }
      }
    
      const componentProps = {
          ...props.componentProps
      }
      const [state, setState] = React.useState({
        imagesPublished: true,
      });
    const setUploadImagesLocationName = (event) => {
        setLocationNames(event.target.value)
    }
    const setUploadImagesDescription = (event) => {
        setDescriptions(event.target.value)
    }

    const clearImages = () => {
        setFilesArray([])
        setImagesArray([])
    }
    const addImage = () => {
        let input = document.createElement('input')
        input.type = 'file';
        input.multiple = 'multiple'
        // input.accept="image/*";
        input.click();
        input.onchange = () => {
            let imgArr = [...imagesArray];
            let fileArr = [...filesArray];
            for (let i =0;i <input.files.length; i++){
                let url = URL.createObjectURL(input.files[i])
                imgArr.push(url);
            }
            fileArr.push(input);
            setFilesArray(fileArr)
            setImagesArray(imgArr)
        }
    }
    const handleChangeImagesPublished = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
      };
    const addImageToAlbum = (albumsID, index) => 
        {
            let albumsObject = albumsArray;
            if (!albumsObject.hasOwnProperty(index))
                albumsObject[index] = []
            albumsObject[index].push(albumsID)
            setAlbumsArray(albumsObject)
        }

    const deleteImageFromAlbum = (albumIndex, index) => 
        {
            let albumsObject = albumsArray;
            albumsObject[index].splice(albumIndex, 1)
            setAlbumsArray(albumsObject)
        }

    const postImages = (index, fileIndex = 0, detailsIndex = 0) => {
        window.location.hash = '';
        window.location.hash = 'mainContainer';
        setUploadingFileIndex(detailsIndex)
        setUploadingImages(true)
        const formData = new FormData();

        if (filesArray[index].files[fileIndex]) {
            formData.append('image' + index + fileIndex, filesArray[index].files[fileIndex]);
            formData.append("imagesLocationName", locationNames?locationNames:"")
            formData.append("imagesDescription", descriptions?descriptions:"")
            formData.append("imagesPublished", state.imagesPublished? "1": "0")    
            if(componentMethods.getLocalStorage('user') && componentMethods.getLocalStorage('user').tokensHash) {
                $.ajax({
                    url: Settings.API_URL + Settings.API_ENDPOINT_UPLOAD_RAW_IMAGES,
                    type: 'POST',
                    data: formData,
                    headers:{
                        "X-Account-Token": componentMethods.getLocalStorage("user").tokensHash
                    },
                    success: function (data) {
                        if (data.httpStatusCode === 200)
                        {
                            let fUploaded = filesUploaded
                            fUploaded.push(fileIndex)
                            setFilesUploaded(fUploaded)
                        } else {
                            let fFailed = filesFailed
                            fFailed.push(fileIndex)
                            setFilesFailed(fFailed)
                        }

                        setSeed(Math.random() + Math.random());
                        let fileIndexRequest = fileIndex+1
                        let indexRequest = index
                        detailsIndex++
                        if (fileIndexRequest > filesArray[index].files.length - 1) {
                            indexRequest = index + 1 
                            fileIndexRequest = 0
                        }
                        if (!filesArray[indexRequest])
                        {
                            setUploadingImages(false)
                            if (filesFailed.length === 0) {
                                setFilesArray([])
                                setImagesArray([])
                                setDescriptions([])
                                setLocationNames([])
                                setAlbumsArray({})
                                setFilesUploaded([])
                                setFilesFailed([])
                            }

                        } else {

                            postImages(indexRequest, fileIndexRequest, detailsIndex)
                        }
                    },
                    error: function (request, status, error) {

                        setSeed(Math.random() + Math.random());
                        componentMethods.addError(componentConstants.language["LANG_ERROR_COULD_NOT_LOAD_IMAGE"])
                    },
                    cache: false,
                    contentType: false,
                    processData: false
                });
            }
        }
    }

       
    const postFailedImages = (fileFailedIndex = 0) => {
        window.location.hash = '';
        window.location.hash = 'mainContainer';
        setUploadingFileIndex(fileFailedIndex)
        setUploadingImages(true)
        const formData = new FormData();

        if (filesFailed[fileFailedIndex]) {
            formData.append('image' + fileFailedIndex, filesArray[0].files[filesFailed[fileFailedIndex]]);
            formData.append("imagesLocationName", locationNames?locationNames:"")
            formData.append("imagesDescription", descriptions?descriptions:"")
            formData.append("imagesPublished", state.imagesPublished? "1": "0")    
            if(componentMethods.getLocalStorage('user') && componentMethods.getLocalStorage('user').tokensHash) {
                $.ajax({
                    url: Settings.API_URL + Settings.API_ENDPOINT_UPLOAD_RAW_IMAGES,
                    type: 'POST',
                    data: formData,
                    headers:{
                        "X-Account-Token": componentMethods.getLocalStorage("user").tokensHash
                    },
                    success: function (data) {
                        if (data.httpStatusCode === 200)
                        {
                            let fUploaded = filesUploaded
                            fUploaded.push(filesFailed[fileFailedIndex])
                            setFilesUploaded(fUploaded)
                            let fFailed = filesFailed
                            fFailed.splice(fileFailedIndex, 1)
                            setFilesFailed(fFailed)
                            setSeed(Math.random() + Math.random());
                        }

                        setSeed(Math.random() + Math.random());
                        let fileIndexRequest = fileFailedIndex+1
                        if (!filesFailed[fileIndexRequest])
                        {
                            setUploadingImages(false)
                            if (filesFailed.length === 0) {
                                setFilesArray([])
                                setImagesArray([])
                                setDescriptions([])
                                setLocationNames([])
                                setAlbumsArray({})
                                setFilesUploaded([])
                                setFilesFailed([])
                            }

                        } else {
                            postFailedImages(fileIndexRequest)
                        }
                    },
                    error: function (request, status, error) {

                        setSeed(Math.random() + Math.random());
                        componentMethods.addError(componentConstants.language["LANG_ERROR_COULD_NOT_LOAD_IMAGE"])
                    },
                    cache: false,
                    contentType: false,
                    processData: false
                });
            }
        }
    }
  return (<div className={classes.rootAddImage}><React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12} md={12} lg={12}>
                    {componentConstants.language.LANG_NEW_IMAGES}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    
                        <React.Fragment>
                            {
                                uploadingImages ?
                                    <React.Fragment>
                                        <LoadingComponent /> 
                                        <Typography id="uploading-images" variant="subtitle1" component="p" style={{textAlign:"center"}}>{componentConstants.language.LANG_IMAGES_UPLOADING}{uploadingFileIndex+1}/{imagesArray.length}</Typography>
                                    </React.Fragment>
                                :       
                                <React.Fragment>
                                    {filesFailed.length === 0?
                                        <Button fullWidth color="secondary" onClick={() => {addImage()} } style={{height: 150, width:150}} ><AddAPhotoIcon /></Button>
                                        :
                                        null
                                    }
                                </React.Fragment>                         
                            }
                            {
                                filesArray.length?
                                    <Paper style={{margin: 8, padding: 8}}>
                                        <TextField name={'imagesLocationName'} value={locationNames} error={componentMethods.getManagerState().formInvalidUploadImagesLocationName} className={classes.formFullWidth} onChange={(event) => {setUploadImagesLocationName(event)}} id="UPLOAD_IMAGES_LOCATION_NAME" label={componentConstants.language.LANG_UPLOAD_IMAGE_LOCATION_NAME} fullWidth={true} />
                                        <TextField name={'imagesDescription'} value={descriptions} error={componentMethods.getManagerState().formInvalidUploadImagesDescription} className={classes.formFullWidth} onChange={(event) => {setUploadImagesDescription(event)}} id="UPLOAD_IMAGES_DESCRIPTION" label={componentConstants.language.LANG_UPLOAD_IMAGE_DESCRIPTION} fullWidth={true} />
                                    </Paper>
                                :
                                    null
                            }
                            {
                                filesArray.map((item, index) => {
                                    let files = Array.from(item.files)
                                    return files.map((itemFile, index2) => {
                                        return <React.Fragment key={index2}>
                                                    <div className={classes.imgContainer}>
                                                        <Paper style={{margin: 8, padding: 8}}>
                                                            <Typography variant="h6">{itemFile.name}</Typography>
                                                            {index2 === uploadingFileIndex && uploadingImages ?
                                                                <LoadingComponent />
                                                                :
                                                                null
                                                            }
                                                            {filesUploaded.indexOf(index2) !== -1 ?
                                                                <IconButton><DoneIcon /></IconButton>
                                                                :
                                                                null
                                                            }
                                                            {filesFailed.indexOf(index2) !== -1 ?
                                                                <IconButton><ClearIcon /></IconButton>
                                                                :
                                                                null
                                                            }
                                                        </Paper>
                                                    </div>
                                                </React.Fragment>
                                    })
                                })
                            }
                            {imagesArray.length > 0?
                                <React.Fragment>
                                    {filesFailed.length && !uploadingImages ?
                                        <Button fullWidth color="secondary" variant="contained" onClick={() => {postImages(0)} }><PublishIcon /> {componentConstants.language.LANG_UPLOAD_FAILED_IMAGES}</Button>
                                        :
                                        null
                                    }
                                    {!uploadingImages && filesFailed.length === 0 && filesArray.length?
                                        <React.Fragment>
                                            <Button style={{marginBottom: 8}} fullWidth color="secondary" variant="contained" onClick={() => {postImages(0)} }><PublishIcon /> {componentConstants.language.LANG_UPLOAD_IMAGES}</Button>
                                            <Button style={{marginBottom: 8}} fullWidth color="secondary" variant="outlined" onClick={() => {clearImages()} } >{componentConstants.language.LANG_CANCEL_IMAGES}</Button>
                                        </React.Fragment>
                                        :
                                        null
                                    }
                                </React.Fragment>
                            :
                                null
                            }
                            
                        </React.Fragment>
                </Grid>
                </Grid>
            </React.Fragment>
            </div>
  );
}