import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/SearchYourImagesStyle';
import Settings from '../../../Settings/Settings'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function SearchAlbums(props) {
    const classes = useStyles();
    const [albumsIsPublished, setAlbumsIsPublished] = React.useState(true)
    const [albumsIsHidden, setAlbumsIsHidden] = React.useState(false)
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const [state, setState] = React.useState({
        albumsIsPublished: true,
        albumsIsHidden: false,
      });

    const handleChangeAlbumsIsPublished = (event) => {
        setAlbumsIsPublished(event.target.checked)
      };
    
    const handleChangeAlbumsIsHidden = (event) => {
        setAlbumsIsHidden(event.target.checked)
      };

    const [albumsName, setAlbumsName] = React.useState("")
    const [errorAlbumsName, setErrorAlbumsName] = React.useState(false)
    const addAlbum = (e) => {
        if (!albumsName)
        {
            setErrorAlbumsName(true)
            componentMethods.addError(componentConstants.language.LANG_ERROR_EMPTY_ALBUM_NAME)
            return
        }
        let endpoint = Settings.API_ENDPOINT_ALBUMS

        let postObject = {
            albumsName: albumsName,
            albumsIsPublished: albumsIsPublished ? '1' : '0',
            albumsIsHidden: albumsIsHidden ? '1' : '0'
        }
        componentMethods.ajax(Settings.API_URL + endpoint,postObject,"POST", {}, (data) => {
            componentMethods.addError(componentConstants.language.LANG_SUCCESSFULLY_ADDED_ALBUM)
            componentMethods.refreshSearchYourAlbums()
            setAlbumsName("")
        })
      }

    return   <React.Fragment>
              <Grid item xs={1} lg={4} md={1}></Grid>
              <Grid item xs={10} lg={4} md={10}>
                <Typography component="h6" variant="h6">{componentConstants.language.LANG_ADD_ALBUM}</Typography>
                <Paper component="div" style={{padding: 8}}>
                    <div>
                    <InputBase
                    className={classes.input}
                    placeholder={componentConstants.language.LANG_ALBUM_NAME}
                    inputProps={{ 'aria-label': componentConstants.language.LANG_ALBUM_NAME}}
                    value={albumsName} 
                    onChange={(event)=>{ setAlbumsName(event.target.value)}}
                    /></div>
                    <div>
                    <FormControlLabel fullWidth={true} className={classes.formFullWidth}
                        control={<Checkbox value={1} checked={albumsIsPublished} onChange={handleChangeAlbumsIsPublished} name="imagesPublished" />}
                        label={componentConstants.language["LANG_ALBUM_PUBLISHED"]}
                    />
                    <FormControlLabel fullWidth={true} className={classes.formFullWidth}
                    control={<Checkbox value={1} checked={albumsIsHidden} onChange={handleChangeAlbumsIsHidden} name="imagesHidden" />}
                    label={componentConstants.language["LANG_ALBUM_HIDDEN"]}
                />
                    </div>
                    <div>
                    <Button type="button" onClick={addAlbum} className={classes.iconButton} aria-label="search" startIcon={<AddIcon />}>
                        {componentConstants.language.LANG_ADD_ALBUM}
                    </Button>
                    </div>
                </Paper> 
            </Grid>
            <Grid item xs={1} lg={4} md={1}></Grid>
    </React.Fragment>
}
