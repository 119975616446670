import React from 'react';
import { useHistory } from "react-router-dom";
import {
    Route
} from "react-router-dom";
import HomeComponent from '../CommonComponents/HomeComponent/HomeComponent'
import MyPicturesComponent from '../CommonComponents/MyPicturesComponent/MyPicturesComponent';
import MyRawPicturesComponent from '../CommonComponents/MyRawPicturesComponent/MyRawPicturesComponent';
import MyVideosComponent from '../CommonComponents/MyVideosComponent/MyVideosComponent';
import UserPicturesComponent from '../CommonComponents/UserPicturesComponent/UserPicturesComponent';
import {
    Switch
} from "react-router-dom";
import { useParams
} from "react-router-dom";
import ImagePopup from '../CommonComponents/ImageComponent/ImagePopup';
import CheckoutComponent from '../CommonComponents/CheckoutComponent/CheckoutComponent';
import OrdersComponent from '../CommonComponents/OrdersComponent/OrdersComponent';
import RewardsComponent from '../CommonComponents/RewardsComponent/RewardsComponent';
import PaymentsComponent from '../CommonComponents/PaymentsComponent/PaymentsComponent';
import RecoverPasswordComponent from '../CommonComponents/RecoverPasswordComponent/RecoverPasswordComponent';
import UserOrdersComponent from '../CommonComponents/UserOrders/UserOrdersComponent'
import UserOrderComponent from '../CommonComponents/UserOrderComponent/UserOrderComponent'
import StaticPageComponent from '../CommonComponents/StaticPagesComponent/StaticPageComponent';
import CookiesSnackbarComponent from '../CommonComponents/CookiesSnackbarComponent/CookiesSnackbarComponent'
import AdminUsersRetourComponent from '../CommonComponents/AdminUsersRetour/AdminUsersRetour'
import UserAlbums from '../CommonComponents/UserAlbums/UserAlbums';
import Albums from '../CommonComponents/Albums/AlbumsComponent'
import StocksComponent from '../CommonComponents/StocksComponent/StocksComponent';
import AdminVouchersComponent from '../CommonComponents/AdminVouchersComponent/AdminVouchersComponent';
import AdminWebsiteVisitorsComponent from '../CommonComponents/AdminWebsiteVisitorsComponent/AdminWebsiteVisitorsComponent';
import AdminEmailsComponent from '../CommonComponents/AdminEmailsComponent/AdminEmailsComponent'
import AdminVideosComponent from '../CommonComponents/AdminVideosComponent/AdminVideosComponent';
import AdminUsersComponent from '../CommonComponents/AdminUsersComponent/AdminUsersComponent'
import AffiliatesComponent from '../CommonComponents/AffiliatesComponent/AffiliatesComponent'
import AdminAffiliatesPaymentsComponent from '../CommonComponents/AdminAffiliatesPaymentsComponent/AdminAffiliatesPaymentsComponent'
import AdminVariantsDimensionsComponent from '../CommonComponents/AdminVariantsDimensionsComponent/AdminVariantsDimensionsComponent'
import YourFavorites from '../CommonComponents/YourFavorites/YourFavorites';
import RandomImages from '../CommonComponents/RandomImagesPage/RandomImages';
import FotoLikeComponent from '../CommonComponents/FotoLikeComponent/FotoLikeComponent';

function HomeComponentSearch(props) {
    let urlParams = useParams();
    return <React.Fragment>
                {
                urlParams.image ?
                    <ImagePopup {...props}  urlParams={urlParams} />
                :
                    <HomeComponent {...props} search={true} urlParams={urlParams} />
                }  
            </React.Fragment>
}

function HomeComponentWithoutSearch(props) {
    let urlParams = useParams();
    return <React.Fragment>
                <HomeComponent {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function HomeComponentWithoutSearchAndWithImage(props) {
    let urlParams = useParams();
    return <React.Fragment>
                <ImagePopup {...props}  urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function MyPicturesComponentWithoutSearch(props) {
    return <React.Fragment>
                <MyPicturesComponent {...props} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function MyPicturesComponentWithoutSearchAndImage(props) {
    let urlParams = useParams();
    return <React.Fragment>
                <ImagePopup {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function MyPicturesSearchComponent(props) {
    let urlParams = useParams();
    return <React.Fragment>
                {
                urlParams.image ?
                    <ImagePopup {...props}  urlParams={urlParams} />
                :
                    <React.Fragment>
                        <MyPicturesComponent {...props} search={true} urlParams={urlParams} />
                        <CookiesSnackbarComponent {...props} />
                    </React.Fragment>
                }       
            </React.Fragment>
}

function MyRawPictures(props) {
    let urlParams = useParams();
    return <React.Fragment>
                <MyRawPicturesComponent {...props} search={true} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} urlParams={urlParams} />
            </React.Fragment>
}

function MyPicturesWithoutSearchAndWithPagination(props) {
    let urlParams = useParams();
    return <React.Fragment>
                {
                urlParams.image ?
                    <ImagePopup {...props}  urlParams={urlParams} />
                :
                    <React.Fragment>
                        <MyPicturesComponent {...props} search={false} urlParams={urlParams} />
                        <CookiesSnackbarComponent {...props} />
                    </React.Fragment>
                }       
            </React.Fragment>
}

function MyVideosSearchComponent(props) {
    let urlParams = useParams();
    return <React.Fragment>                
                <MyVideosComponent {...props} search={true} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function UserPicturesSearchComponent(props) {
    let urlParams = useParams();
    return <React.Fragment>
                 {
                urlParams.image ?
                    <ImagePopup {...props}  urlParams={urlParams} />
                :
                    <React.Fragment>
                        <UserPicturesComponent {...props} search={true} urlParams={urlParams} />
                        <CookiesSnackbarComponent {...props} />
                    </React.Fragment>
                }   
            </React.Fragment>
}

function UserPicturesWithoutSearchComponent(props) {
    let urlParams = useParams();
    return <React.Fragment>
                {
                urlParams.image ?
                    <ImagePopup {...props}  urlParams={urlParams} />
                :
                    <React.Fragment>
                        <UserPicturesComponent {...props} urlParams={urlParams} />
                        <CookiesSnackbarComponent {...props} />
                    </React.Fragment>
                }   
        </React.Fragment>
}
function Orders(props) {
    let urlParams = useParams();
    return <React.Fragment>
                <OrdersComponent {...props} urlParams={urlParams} />
            </React.Fragment>
}
function FotoLike(props) {
    let urlParams = useParams();
    return <React.Fragment>
                <FotoLikeComponent {...props} urlParams={urlParams} />
            </React.Fragment>
}
function Rewards(props) {
      let urlParams = useParams();
    return <React.Fragment>
                <RewardsComponent {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}
function Payments(props) {
      let urlParams = useParams();
    return <React.Fragment>
                <PaymentsComponent {...props} urlParams={urlParams} />
            </React.Fragment>
}
function RecoverPassword(props) {
      let urlParams = useParams();
    return <React.Fragment>
                <RecoverPasswordComponent {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function UserOrders(props) {
      let urlParams = useParams();
    return <React.Fragment>
                <UserOrdersComponent {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function AdminUsersRetourPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <AdminUsersRetourComponent {...props} urlParams={urlParams} />
              <CookiesSnackbarComponent {...props} />
          </React.Fragment>
}

function UserOrder(props) {
      let urlParams = useParams();
    return <React.Fragment>
                <UserOrderComponent {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function UserAlbumsPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <UserAlbums {...props} urlParams={urlParams} />
              <CookiesSnackbarComponent {...props} />
          </React.Fragment>
}

function AlbumsPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
                {
                    urlParams.image ?
                        <ImagePopup {...props}  urlParams={urlParams} />
                    :
                        <React.Fragment>
                            <Albums {...props} urlParams={urlParams} />
                            <CookiesSnackbarComponent {...props} />
                        </React.Fragment>
                }   
          </React.Fragment>
}

function StocksComponentPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <StocksComponent {...props} urlParams={urlParams} />
          </React.Fragment>
}

function AdminVouchersComponentPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <AdminVouchersComponent {...props} urlParams={urlParams} />
          </React.Fragment>
}

function AdminWebsiteVisitorsComponentPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <AdminWebsiteVisitorsComponent {...props} urlParams={urlParams} />
          </React.Fragment>
}

function AdminEmailsPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <AdminEmailsComponent {...props} urlParams={urlParams} />
          </React.Fragment>
}

function AdminVideosPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <AdminVideosComponent {...props} urlParams={urlParams} />
          </React.Fragment>
}

function AdminUsersPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <AdminUsersComponent {...props} urlParams={urlParams} />
          </React.Fragment>
}

function AffiliatesPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <AffiliatesComponent {...props} urlParams={urlParams} />
          </React.Fragment>
}
function AdminAffiliatesPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <AdminAffiliatesPaymentsComponent {...props} urlParams={urlParams} />
          </React.Fragment>
}


function AdminVariantsDimensionsPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <AdminVariantsDimensionsComponent {...props} urlParams={urlParams} />
          </React.Fragment>
}

function YourFavoritesPage(props) {
    let urlParams = useParams();
    return <React.Fragment>
        {
        urlParams.image ?
            <ImagePopup {...props}  urlParams={urlParams} />
        :
            <React.Fragment>
                <YourFavorites {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
        }
    </React.Fragment>
}


function RandomImagesPage(props) {
    let urlParams = useParams();
    return <React.Fragment>
        {
        urlParams.image ?
            <ImagePopup {...props}  urlParams={urlParams} />
        :
            <React.Fragment>
                <RandomImages {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
        }
    </React.Fragment>
}
export default function RouterSwitch(props) {
    function changeRoute(route) {
        window.location.href = route;
    }

    return (<Switch >
                <Route path="/foto/like">
                    <FotoLike {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/recover/password/:uET/:uCF">
                    <RecoverPassword {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/order/:ordersID/:ordersSha">
                    <UserOrder {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/orders/page/:page">
                    <UserOrders {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/orders">
                    <UserOrders {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/payments/page/:page">
                    <Payments {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/payments">
                    <Payments {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/rewards/page/:page">
                    <Rewards {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/rewards">
                    <Rewards {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/checkout">
                    <CheckoutComponent {...props} changeRoute={changeRoute} />
                    <CookiesSnackbarComponent {...props} />
                </Route>
                <Route path="/retours/page/:page/:query">
                    <AdminUsersRetourPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/retours/page/:page">
                    <AdminUsersRetourPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/retours/:query">
                    <AdminUsersRetourPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/retours">
                    <AdminUsersRetourPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/orders/page/:page/:query">
                    <Orders {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/orders/page/:page">
                    <Orders {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/orders/:query">
                    <Orders {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/orders">
                    <Orders {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/favorites/image/:image">
                    <YourFavoritesPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/favorites">
                    <YourFavoritesPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/random/image/:image">
                    <RandomImagesPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/random">
                    <RandomImagesPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/:user/image/:image">
                    <UserPicturesWithoutSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/page/:page/:user/:query/image/:image">
                    <UserPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/page/:page/:user/image/:image">
                    <UserPicturesWithoutSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/:user/:query/image/:image">
                    <UserPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/page/:page/:user/:query">
                    <UserPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/page/:page/:user">
                    <UserPicturesWithoutSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/:user/:query">
                    <UserPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/:user">
                    <UserPicturesWithoutSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/image/:image">
                    <MyPicturesComponentWithoutSearchAndImage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/page/:page/image/:image">
                    <MyPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/page/:page/:query/image/:image">
                    <MyPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/:query/image/:image">
                    <MyPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/page/:page/:query">
                    <MyPicturesSearchComponent {...props} search={true} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/page/:page">
                    <MyPicturesWithoutSearchAndWithPagination {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/:query">
                    <MyPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures">
                    <MyPicturesComponentWithoutSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/raw/pictures/page/:page/:query">
                    <MyRawPictures {...props} search={true} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/raw/pictures/page/:page">
                    <MyRawPictures {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/raw/pictures/:query">
                    <MyRawPictures {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/raw/pictures">
                    <MyRawPictures {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/videos/page/:page/:query">
                    <MyVideosSearchComponent {...props} search={true} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/videos/page/:page">
                    <MyVideosSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/videos/:query">
                    <MyVideosSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/videos">
                    <MyVideosSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/albums/page/:page/:query">
                    <UserAlbumsPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/albums/page/:page">
                    <UserAlbumsPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/albums/:query">
                    <UserAlbumsPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/albums">
                    <UserAlbumsPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/albums/page/:page/:albumsID/search/:query/image/:image">
                    <AlbumsPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/albums/:albumsID/search/:query/image/:image">
                    <AlbumsPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/albums/page/:page/:albumsID/image/:image">
                    <AlbumsPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/albums/:albumsID/image/:image">
                    <AlbumsPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/albums/page/:page/:albumsID/search/:query">
                    <AlbumsPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/albums/:albumsID/search/:query">
                    <AlbumsPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/albums/page/:page/:albumsID">
                    <AlbumsPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/albums/:albumsID">
                    <AlbumsPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/page/:page/search/:query/image/:image">
                    <HomeComponentSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/page/:page/search/:query">
                    <HomeComponentSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/search/:query/image/:image">
                    <HomeComponentSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/search/:query">
                    <HomeComponentSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/page/:page/image/:image">
                    <HomeComponentWithoutSearchAndWithImage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/image/:image">
                    <HomeComponentWithoutSearchAndWithImage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/page/:page">
                    <HomeComponentWithoutSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/stocks/page/:page/:query">
                    <StocksComponentPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/stocks/page/:page">
                    <StocksComponentPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/stocks/:query">
                    <StocksComponentPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/stocks">
                    <StocksComponentPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/admin/variants/dimensions/page/:page/:query">
                    <AdminVariantsDimensionsPage  search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/admin/variants/dimensions/page/:page">
                    <AdminVariantsDimensionsPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/admin/variants/dimensions/:query">
                    <AdminVariantsDimensionsPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/admin/variants/dimensions">
                    <AdminVariantsDimensionsPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/visitors/page/:page/:query">
                    <AdminWebsiteVisitorsComponentPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/vouchers/page/:page/:query">
                    <AdminVouchersComponentPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/vouchers/page/:page">
                    <AdminVouchersComponentPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/vouchers/:query">
                    <AdminVouchersComponentPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/vouchers">
                    <AdminVouchersComponentPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/visitors/page/:page">
                    <AdminWebsiteVisitorsComponentPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/visitors/:query">
                    <AdminWebsiteVisitorsComponentPage search={true} {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/visitors">
                    <AdminWebsiteVisitorsComponentPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/misiunea-noastra">
                    <StaticPageComponent page="misiune"  {...props} changeRoute={changeRoute} />
                    <CookiesSnackbarComponent {...props} />
                </Route>
                <Route path="/afiliere">
                    <StaticPageComponent page="affiliates"  {...props} changeRoute={changeRoute} />
                    <CookiesSnackbarComponent {...props} />
                </Route>
                <Route path="/politica-retur">
                    <StaticPageComponent page="politica-retur"  {...props} changeRoute={changeRoute} />
                    <CookiesSnackbarComponent {...props} />
                </Route>
                <Route path="/contact">
                    <StaticPageComponent page="contact"  {...props} changeRoute={changeRoute} />
                    <CookiesSnackbarComponent {...props} />
                </Route>
                <Route path="/termeni-si-conditii">
                    <StaticPageComponent page="termeni"  {...props} changeRoute={changeRoute} />
                    <CookiesSnackbarComponent {...props} />
                </Route>
                <Route path="/politica-cookie">
                    <StaticPageComponent page="cookie-policy"  {...props} changeRoute={changeRoute} />
                    <CookiesSnackbarComponent {...props} />
                </Route>
                <Route path="/gdpr">
                    <StaticPageComponent page="gdpr"  {...props} changeRoute={changeRoute} />
                    <CookiesSnackbarComponent {...props} />
                </Route>       
                <Route path="/how-to-photographers">
                    <StaticPageComponent page="how-to-photographers"  {...props} changeRoute={changeRoute} />
                    <CookiesSnackbarComponent {...props} />
                </Route>                         
                <Route path="/admin/users/page/:page/search/:query">
                    <AdminUsersPage search={true} {...props} changeRoute={changeRoute} />
                </Route>                       
                <Route path="/admin/users/page/:page">
                    <AdminUsersPage {...props} changeRoute={changeRoute} />
                </Route>    
                <Route path="/admin/users/search/:query">
                    <AdminUsersPage search={true} {...props} changeRoute={changeRoute} />
                </Route>                                
                <Route path="/admin/users/page/:page">
                    <AdminUsersPage {...props} changeRoute={changeRoute} />
                </Route>    
                <Route path="/admin/users">
                    <AdminUsersPage {...props} changeRoute={changeRoute} />
                </Route>   
                <Route path="/admin/emails/page/:page">
                    <AdminEmailsPage {...props} changeRoute={changeRoute} />
                </Route>                                 
                <Route path="/admin/emails">
                    <AdminEmailsPage {...props} changeRoute={changeRoute} />
                </Route>  
                <Route path="/admin/videos/page/:page">
                    <AdminVideosPage {...props} changeRoute={changeRoute} />
                </Route>                                 
                <Route path="/admin/videos">
                    <AdminVideosPage {...props} changeRoute={changeRoute} />
                </Route>  
                <Route path="/affiliates/page/:page">
                    <AffiliatesPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/affiliates">
                    <AffiliatesPage {...props} changeRoute={changeRoute} />
                </Route>  
                <Route path="/admin/affiliates/page/:page">
                    <AdminAffiliatesPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/admin/affiliates">
                    <AdminAffiliatesPage {...props} changeRoute={changeRoute} />
                </Route>                              
                <Route path="/">
                    <HomeComponentWithoutSearch {...props} changeRoute={changeRoute} />
                </Route>
            </Switch>)
}