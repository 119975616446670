import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Settings from '../../../Settings/Settings'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Style from '../Styles/SearchYourImagesStyle';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import $ from "jquery";
import LoadingComponent from '../../LoadingComponent/LoadingComponent';
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Icon, Paper } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function DistinctImagesDescriptions(props) {
    const classes = useStyles();
    const [pagination, setPagination] = React.useState(null)
    const [imagesDistinctDescriptions, setImagesDistinctDescriptions] = React.useState([])
    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods
      }
    
      const componentProps = {
          ...props.componentProps
      }
    useEffect(() => {
        getImagesDistinctDescriptions(1)
    }, []);
    const getImagesDistinctDescriptions = (page) => {
        let url = Settings.API_URL + Settings.API_ENDPOINT_RAW_IMAGES_DISTINCT_DESCRIPTIONS + '?page=' + page
        if (componentConstants.urlParams.query)
        {
            url += '&q=' + componentConstants.urlParams.query
        }
        componentMethods.ajax(url, {},'GET',null, (data) => {
            let items = imagesDistinctDescriptions
            items = items.concat(data.items)
            setImagesDistinctDescriptions(items)
            setPagination(data.pagination)
          })
    }

  return (<Grid container spacing={0}>
                    
                        <React.Fragment>
                            {
                                imagesDistinctDescriptions.map((item, index) => {
                                    if (item.imagesDescription) {
                                        return  <Button style={{margin: 8}} color="secondary" variant="outlined" onClick={() => {getImagesDistinctDescriptions(pagination.next)}}>
                                                        <a href={Settings.URL_RAW_IMAGES  + "/" + item.imagesDescription}>{item.imagesDescription}</a>
                                                </Button>
                                    }
                                })
                            }

                            {pagination && pagination.current < pagination.last ?
                                <Button style={{margin: 8}} color="secondary" variant="outlined" onClick={() => {getImagesDistinctDescriptions(pagination.next)}}>
                                    {componentConstants.language.LANG_LOAD_MORE_DESCRIPTIONS}
                                </Button>
                                
                                :
                                null
                            }
                        </React.Fragment>
            </Grid>
  );
}