let languages = {
    ro: {
        LANG_USERNAME: "Nume utilizator",
        LANG_AUTHENTICATE: 'Autentificare',
        LANG_CONVERSATION: '@utilizator or #canal',
        LANG_MESSAGE: 'Mesaj',
        LANG_ENCRYPT: 'Cripteaza',
        LANG_SET_ENCRYPTION: 'Foloseste criptarea',
        LANG_ENCRYPTION_BYTE: 'Octet pentru criptare',
        LANG_ENCRYPTION_SUM: 'Suma pentru criptare',
        LANG_RESET_ENCRYPTION: 'Reseteaza criptarea',
        LANG_ENCRYPTED: 'Criptat',
        LANG_DELETE: 'Șterge',
        LANG_CANCEL: "Anulează",
        LANG_CLOSE_CHANNEL_TITLE: "Doresti sa inchizi aceasta conversatie?",
        LANG_CLOSE_CHANNEL_TEXT: "Apasand butonul STERGE vei incheia aceasta conversatie",
        LANG_DRAFT: "Mesaj: ",
        LANG_MESSAGE_ENCRYPTED: "Mesaj criptat",
        LANG_SHOPPING_CART: "Coș de cumparaturi",
        LANG_CREATE_ACCOUNT: "Creează cont",
        LANG_UPLOAD_IMAGE: "Încarcă imagine",
        LANG_UPLOAD_IMAGE_LOCATION_NAME: "Locația imaginii",
        LANG_UPLOAD_VIDEO_LOCATION_NAME: "Locația videoclipului",
        LANG_UPLOAD:"Încarcă",
        LANG_CHOOSE_IMAGE:"Alege imagine",
        LANG_SEARCH: "Caută",
        LANG_EMAIL: "Email",
        LANG_PASSWORD: "Parolă",
        LANG_OLD_PASSWORD: "Parola veche",
        LANG_NEW_PASSWORD:"Parola nouă",
        LANG_NEW_PASSWORD_CONFIRM:"Confirmă parola nouă",
        LANG_UPDATE_ACCOUNT:"Actualizează cont",
        LANG_CONFIRM_PASSWORD: "Confirmă parola",
        LANG_DATE_OF_BIRTH_DAY: "Zi",
        LANG_DATE_OF_BIRTH_MONTH: "Lună",
        LANG_DATE_OF_BIRTH_YEAR: "An",
        LANG_DATE_OF_BIRTH: "Zi de naștere",
        LANG_UPDATED_ACCOUNT: "Contul a fost actualizat cu succes",
        LANG_SEX:"Sex",
        LANG_FEMALE:"Femeie",
        LANG_MALE:"Bărbat",
        LANG_OTHER:"Altul",
        LANG_FIRST_NAME:"Prenume",
        LANG_LAST_NAME:"Nume",
        LANG_CLOSE: "Închide",
        LANG_ERROR_INVALID_PASSWORD_CONFIRM: "Parolele nu coincid",
        LANG_ERROR_INVALID_PASSWORD: "Parolă invalidă",
        LANG_VALIDATION_REQUIRED_FIELD: "Câmp obligatoriu",
        LANG_VALIDATION_DATE_FIELD: "Câmp invalid",
        LANG_VALIDATION_INVALID_REQUEST: "Eroare în formular",
        LANG_VALIDATION_INVALID_FIELD: "Câmp invalid",
        LANG_SUCCESSFUL_REGISTER: "Contul a fost creat cu succes, te rugăm să te autentifici",
        LANG_BACK:"Înapoi",
        LANG_NEXT:"Înainte",
        LANG_VALIDATION_UNIQUE: "Câmpul trebuie sa fie unic",
        LANG_usersEmail: "Email",
        LANG_usersDateOfBirth: "Data nașterii",
        LANG_usersFirstName: "Prenume",
        LANG_usersLastName: "Nume",
        LANG_usersPassword: "Parolă",
        LANG_usersUsersSexID: "Sex",
        LANG_ERROR_NON_EXISTING_USERS: "Utilizatorul nu este înregistrat",
        LANG_VALIDATION_INVALID_EMAIL: "Adresă de email invalidă",
        LANG_ERROR_NON_VALID_USER: "Utilizatorul nu există",
        LANG_VALIDATION_MIN_FIELD_LENGTH:"Câmpul nu contine destule caractere",
        LANG_YOUR_ACCOUNT: "Contul tău",
        LANG_LOGOUT: "Ieșire din cont",
        LANG_LOADING: "Se încarcă",
        LANG_ERROR_COULD_NOT_LOAD_IMAGE: "Imaginea nu a putut fi încărcată",
        LANG_UPLOAD_IMAGE_DESCRIPTION: "Descrierea imaginii",
        LANG_UPLOAD_VIDEO_DESCRIPTION: "Descrierea videoclipului",
        LANG_CHANGE_PASSWORD: "Schimbă parola",
        LANG_CANCEL_CHANGE_PASSWORD: "Anulează schimbarea parolei",
        LANG_IMAGE_PUBLISHED: "Imagine publicată",
        LANG_VIDEO_PUBLISHED: "Videoclip publicat",
        LANG_IMAGES_PUBLISHED: "Imagini publicate",
        LANG_YOUR_PICTURES: "Tablourile tale",
        LANG_VIDEOS: "Videoclipuri",
        LANG_YOUR_VIDEOS: "Videoclipurile tale",
        LANG_SEARCH_YOUR_IMAGES: "Caută în tablourile tale",
        LANG_SEARCH_USER_IMAGES: "Caută în tablourile utilizatorului",
        LANG_SEARCH_YOUR_VIDEOS: "Caută în videoclipurile tale",
        LOAD_IMAGES:"Încarcă imaginile",
        LANG_IMAGES: "Imagini",
        LANG_SAVE:"Salvează",
        LANG_IMAGE_UPDATED_SUCCESSFULLY: "Imaginea a fost actualizată cu succes",
        LANG_IMAGE_COULD_NOT_UPDATE: "Imaginea nu a putut fi actualizată, încercați din nou",
        LANG_DELETE_IMAGE_MESSAGE: "Ești sigur că dorești să ștergi imaginea?",
        LANG_DELETE_VIDEO_MESSAGE: "Ești sigur că dorești să ștergi videoclipul?",
        LANG_DELETE_IMAGE_MESSAGE_TITLE: "Șterge imaginea",
        LANG_DELETE_VIDEO_MESSAGE_TITLE: "Șterge videoclipul",
        LANG_DELETE_ALBUM_MESSAGE_TITLE: "Șterge albumul",
        LANG_DELETE_ALBUM_MESSAGE: "Ești sigur că dorești să ștergi albumul?",
        LANG_IMAGE_DELETED: "Imaginea a fost ștearsă",
        LANG_IMAGE_NOT_DELETED: "Imaginea nu putut fi ștearsă",
        LANG_CLOSE_IMAGE: "Închide imaginea",
        LANG_HOME: "Acasă",
        LANG_SEARCH_ALL_IMAGES:"Caută tablouri",
        LANG_SEARCH_ALL_VIDEOS:"Caută videoclipuri",
        LANG_SEARCH_ALL_ALBUMS_IMAGES:"Caută tablouri din album",
        LANG_QUANTITY: "Cantitate",
        LANG_DIMENSIONS:"Dimensiune",
        LANG_TOTAL: "Total",
        LANG_SUBTOTAL: "Subtotal",
        LANG_ADD_TO_SHOPPING_CART: "Adaugă în coș",
        LANG_CURRENCY: "lei",
        LANG_DELETE_SHOPPINGCARTITEM_MESSAGE: "Ești sigur că dorești sa ștergi elementul din coșul de cumpărături?",
        LANG_DELETE_SHOPPINGCARTITEM_MESSAGE_TITLE: "Șterge elementul din coș",
        LANG_ADDRESS_STREET: "Strada",
        LANG_SAVE_ADDRESS: "Salvează adresa",
        LANG_COUNTY: "Județ",
        LANG_ADDRESS_COUNTRY: "Țara",
        LANG_ADDRESS_NUMBER: "Nr.",
        LANG_ADDRESS_BLOCK: "Bloc",
        LANG_ADDRESS_ENTRANCE: "Scara",
        LANG_ADDRESS_APARTMENT: "Apartament",
        LANG_ADDRESS_CITY: "Localitate",
        LANG_ADDRESS_PHONE_NUMBER: "Număr telefon",
        LANG_ADDRESS_FLOOR: "Etaj",
        LANG_ADDRESS_OBSERVATIONS: "Observații",
        LANG_ADDRESS_FIRST_NAME: "Prenume",
        LANG_ADDRESS_LAST_NAME: "Nume",
        LANG_ERROR_EMPTY_ADDRESS_STREET: "Numele străzii este invalid.",
        LANG_ERROR_EMPTY_ADDRESS_LAST_NAME: "Numele din adresă este invalid.",
        LANG_ERROR_EMPTY_ADDRESS_FIRST_NAME: "Prenumele din adresă este invalid.",
        LANG_ERROR_EMPTY_FIRST_NAME: "Prenumele este gol",
        LANG_ERROR_EMPTY_LAST_NAME: "Numele este gol",
        LANG_ERROR_EMPTY_EMAIL: "Emailul este gol",
        LANG_ERROR_EMPTY_EMAIL_AND_PHONE: "Emailul si telefonul nu sunt completate. Completati cel putin unul din acest campuri.",
        LANG_ERROR_EMPTY_MESSAGE: "Mesajul este gol.",
        LANG_SUCCESSFULY_SAVED_GDPR_REQUEST: "Cererea de stergere conform GDPR a fost salvată",
        LANG_SUCCESSFULY_SAVED_CONTACT_MESSAGE: "Mesajul de contact a fost salvat",
        LANG_SEND_REQUEST: "Trimite cererea",
        LANG_SEND_MESSAGE: "Trimite mesajul",
        LANG_ADDRESES: "Adrese",
        LANG_ERROR_EMPTY_ADDRESS_CITY:  "Orașul din adresă este invalid.",
        LANG_ERROR_EMPTY_PHONE_NUMBER: "Numărul de telefon din adresă este invalid.",
        LANG_ERROR_EMPTY_COUNTIES: "Județul din adresă este invalid",
        LANG_ERROR_EMPTY_BLOCK_AND_NUMBER: "Adresa nu conține număr și/sau bloc",
        LANG_SUCCESSFULY_SAVED_ADDRESS: "Adresa a fost salvată cu succes",
        LANG_ADD_ADDRESS: "Adaugă adresă",
        LANG_CANCEL_ADD_ADDRESS: "Anulează adăugarea adresei",
        LANG_EDIT_ADDRESS: "Modifică adresa",
        LANG_CANCEL_EDIT_ADDRESS: "Anulează modificarea",
        LANG_DELETE_ADDRESS_MESSAGE: "Ești sigur că dorești sa ștergi adresa?",
        LANG_DELETE_ADDRESS_MESSAGE_TITLE: "Șterge adresa",
        LANG_ADDRESS_NOT_DELETED: "Adresa nu a putut fi ștearsă",
        LANG_ADDRESS_DELETED: "Adresa a fost ștearsă",
        LANG_PROCEED_TO_CHECKOUT: "Trimite comanda",
        LANG_SEND_ORDER: "Trimite comanda",
        LANG_DELIVERY_ADDRESS: "Adresă de livrare",
        LANG_BILLING_ADDRESS: "Adresă de facturare",
        PAYMENT_METHOD_RAMBURS_ON_DELIVERY: "ramburs",
        LANG_PAYMENT_METHOD_RAMBURS: "Ramburs la curier",
        LANG_PAYMENT_METHODS: "Metode de plată",
        LANG_PAPER_TYPE_CANVAS: "canvas",
        LANG_PAPER_TYPE_PHOTO_PAPER: "hârtie lucioasă fotografică - A3",
        LANG_CLICK_TO_SELECT_VARIANT: "Click pentru a selecta produsul",
        LANG_PRODUCT_CODE: "Cod produs",
        DELIVERY_METHOD_COURIER: "courier",
        LANG_DELIVERY_METHOD: "Metodă de livrare",
        LANG_DELIVERY_METHOD_COURIER: "Curier rapid",
        LANG_ORDER_OBSERVATIONS: "Observații comandă",
        LANG_ERROR_EMPTY_SHOPPINGCART_ITEMS: "Coșul de cumpărături este gol",
        LANG_ERROR_EMPTY_DELIVERY_ADDRESS: "Adresa de livrare nu a fost selectată",
        LANG_ERROR_EMPTY_BILLING_ADDRESS: "Adresa de facturare nu a fost selectată",
        LANG_ERROR_EMPTY_PAYMENT_METHOD: "Metoda de plată nu a fost selectată",
        LANG_ERROR_EMPTY_DELIVERY_METHOD: "Metioda de livrare nu a fost selectată",
        LANG_SUCCESSFULY_SENT_ORDER: "Comanda a fost trimisă cu succes",
        LANG_FAILED_SEND_ORDER: "Comanda nu a putut fi trimisă",
        LANG_SELECT_DIMENSIONS: "Alege dimensiune",
        LANG_SELECT_QUANTITY: "Alege cantitate",
        LANG_ORDERS: "Comenzi",
        LANG_ORDERS_ID: "Id comandă",
        LANG_ORDERS_USERS_FIRST_NAME_USERS_LAST_NAME: "Nume / Prenume",
        LANG_ORDERS_DELIVERY_ADDRESS: "Adresă de livrare",
        LANG_ORDERS_BILLING_ADDRESS: "Adresă de facturare",
        LANG_ORDERS_STATUS: "Status comandă",
        LANG_ORDERS_TOTAL: "Total",
        LANG_ORDERS_DELIVERY_METHOD: "Metodă de livrare",
        LANG_ORDERS_PAYMENT_METHOD: "Metodă de plată",
        LANG_ORDERS_OBSERVATIONS: "Observații",
        LANG_ORDER_UPDATED: "Comanda a fost actualizată",
        LANG_ORDER_NOT_UPDATED: "Comanda nu a fost actualizată",
        LANG_ORDERS_STATUSES_PLACED: "Comandă plasată",
        LANG_ORDERS_STATUSES_PROCESSING: "Comandă în procesare",
        LANG_ORDERS_STATUSES_CANCELED: "Comandă anulată",
        LANG_ORDERS_STATUSES_IN_DELIVERY: "Comandă în livrare",
        LANG_ORDERS_STATUSES_DELIVERED: "Comandă livrată",
        LANG_ORDERS_STATUSES_PAYMENT_PENDING: "Comandă cu plata in așteptare",
        LANG_ORDER_STATUSES_CANNOT_RETOUR: "Fara retur",
        LANG_UPDATE_ORDERS_STATUS: "Actualizează status",
        LANG_UPDATE_PAYMENTS_STATUS: "Actualizează status",
        LANG_ORDERS_ITEMS: "Elemente comandă",
        LANG_UNIT_OF_MEASURE: "mm",
        LANG_ORDERS_SHOW_ITEMS: "Afișează elemente",
        LANG_ORDERS_HIDE_ITEMS: "Ascunde elemente",
        LANG_ORDER_ITEMS_DIALOG_TITLE: "Comanda #",
        LANG_ORDER_ITEMS_VARIANTS_AND_IMAGE: "Cod produs / imagine",
        LANG_UPDATE_ORDERS_ITEMS_QUANTITY: "Actualizează cantitate",
        LANG_PAPER_TYPE: "Tip hârtie",
        LANG_ACTIONS:"Acțiuni",
        LANG_CONFIRM_DELETE: "Confirmă ștergerea",
        LANG_SEARCH_ORDERS: "Caută comenzi",
        LANG_ERROR_EMPTY_ORDERS_EMAIL: "Adresa de email nu este completată",
        LANG_ORDER_EMAIL: "Adresa de email",
        LANG_MONETIZATION: "Monetizare",
        LANG_MONETIZATION_UNIT: "Monetizare (unitar)",
        LANG_MONETIZATION_TOTAL: "Monetizare (total)",
        LANG_MONETIZATION_TOTAL_THIS_MONTH: "Total luna aceasta: ",
        LANG_PAYMENTS_DATE: "Perioadă",
        LANG_PAYMENTS_STATUS: "Status plată",
        LANG_PAYMENTS_AMOUNT: "Total",
        LANG_PAYMENTS_STATUS_NOT_SENT: "Plată netrimisă",
        LANG_PAYMENTS_STATUS_SENT: "Plată trimisă",
        LANG_PAYMENTS: "Plăți",
        LANG_PAYMENTS_ID: "ID Plată",
        LANG_SHOW_CURRENT_REWARDS: "Afișează monetizarea curentă",
        LANG_HIDE_CURRENT_REWARDS: "Ascunde monetizarea curentă",
        LANG_RECOVER_PASSWORD: "Ai uitat parola?",
        LANG_RECOVER: "Recuperează",
        LANG_SUCCESSFUL_RECOVER_EMAIL_SENT: "Emailul de recuperare a parolei a fost trimis.",
        LANG_CONFIRM_NEW_PASSWORD: "Confirmă noua parolă",
        LANG_PASSWORD_CHANGED_SUCCESSFULLY: "Parola a fost schimbată cu succes",
        LANG_PASSWORD_NOT_CHANGED: "Parola nu a putut fi schimbată",
        LANG_PASSWORD_MISMATCH: "Parolele nu coincid",
        LANG_PASSWORD_EMPTY: "Parola este goală",
        LANG_IMAGE_PRE_TITLE: "Tablou de",
        LANG_PAYMENT_UPDATED: "Plata a fost actualizată",
        LANG_ORDER_DETAILS: "Detailii comandă",
        LANG_YOUR_ORDERS: "Comenzile tale",
        LANG_ORDER: "Comandă",
        LANG_ORDER_ITEMS_VARIANT_ID: "ID Produs",
        LANG_ORDER_ITEMS_IMAGE: "Imagine",
        LANG_UNIT_PRICE: "Preț unitar",
        LANG_ITEM_ADDED_TO_CART:"Produsul a fost adăugat în coșul de cumpărături",
        LANG_ITEM_NOT_ADDED_TO_CART: "Elementul nu a putut fi adăugat în coșul de cumpărături",
        LANG_EMPTY_USER_ORDER: "Ne pare rău, dar această comandă fie nu există, fie trebuie să te autentifici pentru a o vizualiza ori nu ai acces la ea.",
        LANG_COOKIE_SNACKBAR_TEXT: "Folosim modulul cookies pentru a îmbunătăți experiența pe acest site.",
        LANG_ACCEPT_COOKIE_POLICY: "Acceptă cookies",
        LANG_OUR_MISSION: "Misiunea noastră",
        LANG_CONTACT: "Contact",
        LANG_COOKIE_POLICY: "Politica cookie",
        LANG_GDPR: "GDPR și Protecția Datelor Personale",
        LANG_TERMS_AND_CONDITIONS: "Termeni și condiții",
        LANG_ORDERS_EMAIL: "Email comandă",
        LANG_ERROR_ON_LOGIN: "Eroare la autentificare",
        LANG_DETAILS: "Detalii",
        LANG_DELIVERY_METHOD_SAME_DAY: "Same Day Curier",
        LANG_DELIVERY_METHOD_FAN_CURIER: "Fan Curier",
        LANG_DELIVERY_METHOD_DPD: "DPD Curier",
        LANG_DELIVERY_METHOD_IN_BACAU: "Predare personala în orașul Bacău",
        LANG_DELIVERY_METHOD_ACASA_CAFFE: "Ridicare personala de la Acasa Caffe Bacau",
        LANG_TOTAL_ORDER: "Total (incl. methodă de livrare)",
        LANGUAGE_LINK_COPPIED_TO_CLIPBOARD: "Adresa URL a fost copiată",
        LANG_ORDER_COMPLETED_TITLE: "Comandă plasată cu succes",
        LANG_ORDER_COMPLETED_TEXT: "Vom trimite un email către adresa ",
        LANG_ORDER_COMPLETED_TEXT_1: " pentru a te ține la curent cu starea comenzii",
        LANG_AGREE_TERMS_AND_CONDITIONS: "Sunt de acord cu",
        LANG_AGREE_TERMS_AND_CONDITIONS_NAME: "termenii și condițiile",
        LANG_ERROR_TERMS_AND_CONDITIONS: "Trebuie să agreezi termenii și condițiile",
        LANG_CONTINUE_SHOPPING: "Continuă cumpărăturile",
        LANG_WE_INCLUDE_FRAMES: "Produsul conține și rama foto",
        LANG_RETOUR_POLICY: "Politică retur",
        LANG_RETOUR_ITEM: "Retur tablou",
        LANG_RETOUR_MESSAGE: "Mesaj retur",
        LANG_SEND_RETOUR_REQUEST: "Trimite cerere retur",
        LANG_SUCCESSFULY_POSTED_RETOUR_REQUEST: "Cererea de retur a fost transmisa",
        LANG_RETOURS: "Retururi",
        LANG_LEXIE_HOME_PAGE_HEADER: "Lexie Art - Magazin online de tablouri",
        LANG_LEXIE_HOME_PAGE_TEXT_1: "Bine ai venit în atelierul nostru virtual de artă fotografică, unde imaginația își găsește contur și poți aduce realitatea în casa ta.",
        LANG_LEXIE_HOME_PAGE_TEXT_2: "Descoperă cele mai spectaculoase peisaje, arhitectură de poveste și natură în toată splendoarea sa, toate la un click distanță.",
        LANG_LEXIE_HOME_PAGE_TEXT_3: "Cu doar câțiva pași, alege imaginea care te conectează profund și noi o vom înrăma cu grijă, transformând-o într-o piesă de artă ce va învia spațiul tău.",
        LANG_LEXIE_HOME_PAGE_TEXT_4: "Transformă-ți visul vizual în realitatea zilnică!",
        LANG_YOUR_ALBUMS: "Albumele tale",
        LANG_ERROR_EMPTY_ALBUM_NAME: "Numele albumului este gol",
        LANG_SUCCESSFULLY_ADDED_ALBUM: "Albumul a fost creat cu succes",
        LANG_ALBUM_NAME: "Denumire album",
        LANG_ALBUM_PUBLISHED: "Album publicat",
        LANG_ALBUM_HIDDEN: "Afișează imaginile nepublicate",
        LANG_ALBUM_UPDATED_SUCCESSFULLY: "Albumul a fost actualizat cu succes",
        LANG_ALBUM_COULD_NOT_UPDATE: "Albumul nu a putut fi actualizat",
        LANG_ALBUM_DELETED: "Albumul a fost șters",
        LANG_ALBUM_NOT_DELETED: "Albumul nu a putut fi șters",
        LANG_ADD_TO_ALBUM: "Adaugă la album",
        LANG_IMAGE_ADDED_TO_ALBUM: "Imaginea a fost adaugată la album",
        LANG_IMAGE_NOT_ADDED_TO_ALBUM: "Imaginea nu a putut fi adaugată la album",
        LANG_IMAGES_DELETED_FROM_ALBUM: "Imaginea a fost ștearsă din album",
        LANG_IMAGES_NOT_DELETED_FROM_ALBUM: "Imaginea nu putut fi ștearsă din album",
        LANG_SEARCH_YOUR_ALBUMS: "Caută albumele tale",
        LANG_BY: "de",
        LANG_ALBUMS_IMAGES_PATH_SET: "Imaginea a fost salvata ca imaginea albumului",
        LANG_ALBUMS_IMAGES_PATH_NOT_SET: "Imaginea nu putut fi salvata ca imaginea albumului",
        LANG_ALBUMS: "Albume",
        LANG_MORE_ALBUMS: "Mai multe albume",
        LANG_STOCKS: "Stocuri",
        LANG_SEARCH_PRODUCTS: "Cauta produse",
        LANG_STOCK_ADDED: "Stocul a fost adăugat",
        LANG_STOCK_UPDATED: "Stocul a fost actualizat",
        LANG_ADD_QUANTITY: "Adaugă cantitate",
        LANG_EXISTING_QUANTITY: "Cantitate existentă",
        LANG_VOUCHERS: "Vouchere",
        LANG_VOUCHER: "Voucher",
        LANG_VOUCHERS_TYPE: "Tip voucher",
        LANG_VOUCHERS_VALUE: "Valoare voucher",
        LANG_VOUCHERS_CODE: "Cod voucher",
        LANG_VOUCHERS_ID: "ID voucher",
        LANG_VOUCHERS_START_DATE: "Date incepere valabilitate",
        LANG_VOUCHERS_END_DATE: "Data sfarsit valabilitate",
        LANG_VOUCHERS_QUANTITY: "Cantitate vouchere",
        LANG_VOUCHERS_USAGES: "Utilizari vouchere",
        LANG_ERROR_EMPTY_VOUCHERS_CODE: "Codul de voucher este gol",
        LANG_ERROR_EMPTY_VOUCHERS_VALUE: "Cantiatea voucherului este goală",
        LANG_ERROR_EMPTY_VOUCHERS_TYPE: "Tipul voucherului este gol",
        LANG_ERROR_EMPTY_VOUCHERS_START_DATE_TIME: "Data de începere a valabilității voucherului este goală",
        LANG_ERROR_EMPTY_VOUCHERS_END_DATE_TIME: "Data de terminare a valabilității voucherului este goală",
        LANG_ERROR_EMPTY_VOUCHERS_QUANTITY: "Cantiatea voucherului este goală",
        LANG_ERROR_EMPTY_VOUCHERS_QUANTITY: "Cantiatea voucherului este goală",
        LANG_SUCCESSFULY_SAVED_VOUCHER: "Voucherul a fost adăugat cu succes",
        LANG_VOUCHERS_TYPE_VALUE: "Valoare",
        LANG_VOUCHERS_TYPE_PERCENTAGE: "Procent",
        LANG_VOUCHERS_TYPE_1FREE12999: "1 Gratis la 129.99",
        LANG_VOUCHERS_TYPE_VALUE_FOR_EVERY_ITEM: "Valoare pentru fiecare element",
        LANG_SAVE_VOUCHER: "Salvează voucher",
        LANG_ADD_VOUCHER: "Adaugă voucher",
        LANG_DELETE_VOUCHER: "Șterge voucher",
        LANG_NOT_SAVED_VOUCHER: "Voucherul nu a putut fi salvat",
        LANG_VOUCHERS_SAVED: "Voucherul a fost actualizat cu succes",
        LANG_VOUCHERS_IS_INVALID: "Voucherul nu este valid",
        LANG_COULD_NOT_DELETE_VOUCHER: "Voucherul nu a putut fi șters",
        LANG_DELETED_VOUCHER: "Voucherul a fost șters",
        LANG_INVALID_VOUCHER: "Voucherul nu este valid",
        LANG_ORDERS_VOUCHERS_DISCOUNT: "Discount voucher",
        LANG_EMAILS_ID: "ID Email",
        LANG_EMAILS_TYPE: "Tip Email",
        LANG_EMAILS_ADDED_DATE_TIME: "Data adăugat",
        LANG_EMAILS_SENT_DATE_TIME: "Data trimis",
        LANG_EMAILS_TEMPLATE_FILE: "Fisier template",
        LANG_EMAILS_STATUS: "Status email",
        LANG_EMAILS_TITLE: "Titlu email",
        LANG_EMAILS_PARAMS: "Parametri email",
        LANG_EMAILS_TO: "Catre",
        LANG_EMAILS: "Emailuri",
        LANG_EMAILS_STATUS_ADDED: "Adăugat",
        LANG_EMAILS_STATUS_SENT: "Trimis",
        LANG_EMAILS_STATUS_FAILED: "Eșuat",
        LANG_EMAILS_STATUS_RETRY: "Retrimitere",
        LANG_VIDEOS_ID: "ID Videoclip",
        LANG_VIDEOS_OPEN: "Deschide videoclip 720p",
        LANG_VIDEOS_OPEN_1080: "Deschide videoclip 1080p",
        LANG_VIDEOS_DESCRIPTION: "Descriere videoclip",
        LANG_VIDEOS_LOCATION: "Locatie videoclip",
        LANG_VIDEOS_UPLOADED_DATE_TIME: "Data încărcare videoclip",
        LANG_VIDEOS_USER: "Utilizator",
        LANG_USERS: "Utilizatori",
        LANG_USERS_ID: "ID Utilizator",
        LANG_USERS_EMAIL: "Email Utilizator",
        LANG_USERS_FIRST_NAME: "Prenume utilizator",
        LANG_USERS_LAST_NAME: "Nume utilizator",
        LANG_USERS_DATE_OF_BIRTH: "Data nasterii",
        LANG_USERS_LAST_LOGIN:"Data ultima autentificare",
        LANG_USERS_SEX: "Sex",
        LANG_WEBSITE_VISITORS_ID: "ID Vizitator",
        LANG_WEBSITE_VISITORS_IP_SHA: "IP SHA Vizitator",
        LANG_WEBSITE_VISITORS_DATETIME: "Data Vizitator",
        LANG_WEBSITE_VISITORS_REFERRER: "Url referinta",
        LANG_WEBSITE_VISITORS_SECTION: "Sectiune",
        LANG_WEBSITE_VISITORS_USER_AGENT: "User Agent",
        LANG_WEBSITE_VISITORS_REQUEST_URI: "URL Accesat",
        LANG_WEBSITE_VISITORS: "Vizitatori website",
        LANG_MAKE_AFFILIATE: "Activează afiliere",
        LANG_DELETE_AFFILIATE: "Șterge afiliere",
        LANG_AFFILIATE: "Afiliat",
        LANG_AFFILIATES: "Afilieri",
        LANG_AFFILIATES_PERCENTAGE: "Procentaj",
        LANG_AFFILIATES_ADMIN: "Admin Afiliati",
        LANG_PAYMENTS_AFFILIATES: "Plăți afilieri",
        LANG_AFFILIATES_LINK: "Link de afiliere",
        LANG_VARIANTS_DIMENSIONS: "Dimensiuni variante",
        LANG_VARIANTS_DIMENSIONS_ID: "ID Dimensiuni variante",
        LANG_VARIANTS_DIMENSIONS_SIZE_BIG: "Dimensiune mare",
        LANG_VARIANTS_DIMENSIONS_SIZE_SMALL: "Dimensiune mica",
        LANG_VARIANTS_DIMENSIONS_PRICE: "Pret",
        LANG_VARIANTS_DIMENSIONS_PAPER_TYPE: "Tip hartie",
        LANG_VARIANTS_DIMENSIONS_ACTIVE: "Activ",
        LANG_UPDATE_PRICE: "Actualiează preț",
        LANG_WEBSITE_VISITORS: "Vizitatori website",
        LANG_WEBSITE_MOTO: "Lexie Art - Magazin online de tablouri - ",
        LANG_SEND_COMMENT: "Trimite comentariu",
        LANG_COMMENT: "Comentariu",
        LANG_COMMENTS: "Comentarii",
        LANG_LOAD_MORE_COMMENTS: "Mai multe comentarii ...",
        LANG_IMAGE_POPUP_SUBTITLE_1: "Fiecare fotografie din colecția noastră este imprimată pe hârtie fotografică lucioasă de calitate superioară, format A3, pentru a vă oferi o experiență vizuală remarcabilă.",
        LANG_IMAGE_POPUP_SUBTITLE_2: "Cu o ramă de sticlă și prindere prin cleme metalice, fiecare tablou devine nu doar o piesă de artă, ci și un element sofisticat pentru decorul casei dumneavoastră.",
        LANG_IMAGE_POPUP_TITLE_3: "Descopera Tablouri Living: Artă pe Hartie Fotografică de Calitate!",
        LANG_PHOTOGRAPHER: "Fotograf",
        LANG_MAKE_PHOTOGRAPHER: "Activează fotograf",
        LANG_PHOTOGRAPHER_REWARD: "Valoarea recompensă",
        LANG_COPY_AFFILIATE_LINK: "Copiază linkul de afiliere",
        LANG_LEXIE_HOW_ITS_MADE: "Cum se fabrică",
        LANG_PHOTOS_OF: "Fotografiile lui ",
        LANG_I_FEE_LUCKY_SHOW_RANDOM_IMAGES: "Mă simt norocos (afișează tablouri aleatorii)",
        LANG_I_DONT_FEEL_LUCKY: "Inapoi la pagina principala",
        LANG_ACCESS_LEVELS: "Niveluri de acces",
        LANG_SHARE: "Distribuie",
        LANG_I_LIKE: "Îmi place",
        LANG_MORE_PHOTOGRAPHERS: "Mai mulți fotografi",
        LANG_PHOTOGRAPHERS: "Fotografi",
        LANG_ADD_COMMENTS: "Adaugă comentariu",
        LANG_LOAD_MORE_IMAGES: "Încarcă mai multe tablouri",
        LANG_LOAD_MORE_VIDEOS: "Încarcă mai multe videoclipuri",
        LANG_LATEST_IMAGES: "Ultimele tablouri adăugate",
        LANG_RANDOM_IMAGES: "Tablouri aleatorii",
        LANG_NO_RESULTS: "Nu există rezultate pentru căutare",
        LANG_ANONYMOUS: "Anonim",
        LANG_UPLOAD_IMAGES: "Încarcă imaginile",
        LANG_UPLOAD_VIDEOS: "Încarcă videoclipurile",
        LANG_IMAGES_UPLOADING: "Se încarcă imaginile: ",
        LANG_CANCEL_IMAGES: "Anulează imaginile",
        LANG_CANCEL_VIDEOS: "Anulează videoclipurile",
        LANG_LIKED_YOUR_FOTO: "i-a plăcut fotografia ta",
        LANG_COMMENTED_YOUR_FOTO: "a comentat la fotografia ta",
        LANG_LOAD_MORE_ALERTS: "Încarcă mai multe notificări",
        LANG_YOU_FAVORITE_IMAGES: "Tablourile tale preferate",
        LANG_LOAD_MORE_FAVORITE_IMAGES: "Încarcă mai multe tablouri favorite",
        LANG_CANCEL_SEARCH: "Anulează căutarea",
        LANG_YOUR_FAVORITES: "Preferatele tale",
        LANG_MAKE_PROFILE_PICTURE: "Fotografie de profil",
        LANG_MAKE_COVER_PICTURE: "Fotografie de coperta",
        LANG_CHOOSE_PROFILE_PICTURE: "Alege fotografie de profil",
        LANG_PROMOTED_IMAGES: "Tablouri promovate",
        LANG_PROMOTE_IMAGE: "Promovează tablou",
        LANG_MORE: "Mai multe",
        LANG_I_LIKE: "Îmi place",
        LANG_I_DONT_LIKE: "Nu-mi place",
        TRY_PHOTO_LIKE: "Încercați FOTO LIKE",
        LANG_ADD_ALBUM: "Adaugă album",
        LANG_RAW_PICTURES: "Imagini RAW",
        LANG_UPLOAD_FAILED_IMAGES: "Încarcă imaginile care au eșuat",
        LANG_LOAD_MORE_DESCRIPTIONS: "Încarcă mai multe descrieri"
    },
    en: {
        LANG_AUTHENTICATE: "Authenticate",
        LANG_USERNAME: 'Username',
        LANG_CONVERSATION: '@username or #channel',
        LANG_MESSAGE: 'Message',
        LANG_ENCRYPT: 'Encrypt',
        LANG_SET_ENCRYPTION: 'Set encryption',
        LANG_ENCRYPTION_BYTE: 'Encryption byte',
        LANG_ENCRYPTION_SUM: 'Encryption sum',
        LANG_RESET_ENCRYPTION: 'Reset encryption',
        LANG_ENCRYPTED: 'Encrypted',
        LANG_DELETE: 'Delete',
        LANG_CANCEL: "Cancel",
        LANG_CLOSE_CHANNEL_TITLE: "Do you want to close this conversation?",
        LANG_CLOSE_CHANNEL_TEXT: "By clicking DELETE you will close this conversation",
        LANG_DRAFT: "Draft: ",
        LANG_MESSAGE_ENCRYPTED: "Ecrypted message"
    }
}

export default languages
