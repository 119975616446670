const settings = {
    defaultLanguage: 'ro',
    API_URL: 'https://apiart.lexie.xyz/',
    LINKS_SITE_URL: "https://l.lexie.xyz/",
    API_IMAGES_URL: "https://i.lexie.xyz/",
    API_ENDPOINT_ALERTS_COUNT: "my/alerts/count",
    API_ENDPOINT_ALERTS: "my/alerts",
    API_ENDPOINT_ALERTS_OLDER: "my/alerts/older/",
    API_ENDPOINT_REGISTER_YEAR: 'users/register/years',
    API_ENDPOINT_REGISTER:'users/register',
    API_ENDPOINT_LOGIN: 'users/login',
    API_ENDPOINT_UPDATE_PROFILE: 'account',
    API_ENDPOINT_MY_ACCOUNT: 'users/account',
    API_ENDPOINT_UPLOAD_IMAGES: 'art/images',
    API_ENDPOINT_GET_RAW_IMAGES: 'art/raw/images',
    API_ENDPOINT_UPLOAD_RAW_IMAGES: 'art/raw/images/without/variants',
    API_ENDPOINT_UPLOAD_VIDEOS: 'art/videos',
    API_ENDPOINT_IMAGES_WITH_VARIANTS: 'art/images/with/variants',
    API_ENDPOINT_FILE: 'art/file/image/',
    API_ENDPOINT_VIDEO_FILE: 'art/file/video/',
    API_ENDPOINT_FILE_FULL: 'file',
    API_ENDPOINT_SEARCH_IMAGES: 'art/search/images',
    API_ENDPOINT_SEARCH_IMAGES_WITH_VARIANTS: 'art/search/images/with/variants',
    API_ENDPOINT_SEARCH_IMAGES_OLDER: 'art/search/images/older/',
    API_ENDPOINT_SEARCH_IMAGES_OLDER_WITH_VARIANTS: 'art/search/images/older/with/variants/',
    API_ENDPOINT_MY_IMAGES: 'art/my/images',
    API_ENDPOINT_MY_VIDEOS: 'art/my/videos',
    API_ENDPOINT_VIDEOS: 'art/videos',
    API_ENDPOINT_VIDEOS_PAGINATED: 'art/paginated/videos',
    API_ENDPOINT_USER_IMAGES: 'art/user/images/',
    API_ENDPOINT_USER_IMAGES_WITH_VARIANTS: 'art/user/images/with/variants/',
    API_ENDPOINT_USER_IMAGES_OLDER_WITH_VARIANTS: 'art/user/images/older/with/variants/',
    API_ENDPOINT_USER_IMAGES_OLDER: 'art/user/images/older/',
    API_ENDPOINT_PUT_IMAGES: 'art/images/',
    API_ENDPOINT_PUT_RAW_IMAGES: 'art/raw/images/',
    API_ENDPOINT_PUT_VIDEOS: 'art/videos/',
    API_ENDPOINT_RAW_IMAGES: 'art/raw/images',
    API_ENDPOINT_IMAGES_VARIANT: 'art/images/variants/',
    API_ENDPOINT_SHOPPINGCART: 'art/shoppingcart',
    API_ENDPOINT_SHOPPINGCART_MERGE: 'art/shoppingcart/merge/',
    API_ENDPOINT_SHOPPINGCARTITEMS: 'art/shoppingcartitems/',
    API_ENDPOINT_COUNTIES: 'art/counties',
    API_ENDPOINT_ADDRESS: 'art/address',
    API_ENDPOINT_ORDER: 'art/order/',
    API_ENDPOINT_ORDERS: 'art/orders',
    API_ENDPOINT_ORDERS_STATUSES: 'art/orders/statuses',
    API_ENDPOINT_ORDERS_INVOICE_ADDRESS: "art/order/invoice/address/",
    API_ENDPOINT_ORDERS_DELIVERY_ADDRESS: "art/order/delivery/address/",
    API_ENDPOINT_ORDER_ITEMS: 'art/orders/items/',
    API_ENDPOINT_USER_REWARDS: "art/user/rewards/current",
    API_ENDPOINT_USER_PAYMENTS: 'art/user/payments',
    API_ENDPOINT_ADMIN_PAYMENTS: 'art/admin/payments',
    API_ENDPOINT_USER_RECOVER_PASSWORD: 'users/recover',
    API_ENDPOINT_USER_RECOVER_PASSWORD_NEW: 'users/recover/password',
    API_ENDPOINT_USER_ORDERS: 'art/orders/user',
    API_ENDPOINT_PAYMENTS_STATUSES: 'art/payments/statuses',
    API_ENDPOINT_PAYMENTS: 'art/payments/',
    API_ENDPOINT_USER_ORDER_INVOICE_ADDRESS: 'art/user/order/invoice/address/',
    API_ENDPOINT_USER_ORDER_DELIVERY_ADDRESS: 'art/user/order/delivery/address/',
    API_ENDPOINT_SHOPPINGCART_TOTAL: 'art/shoppingcart/total/',
    SETTING_ACCESS_LEVEL_PHOTOGRAPHER: 98,
    SETTING_ACCESS_LEVEL_ORDER_PROCESSER: 97,
    SETTING_ACCESS_LEVEL_IMAGE_DOWNLOADER: 100,
    SETTING_ACCESS_LEVEL_GODLIKE: 0,
    SETTING_ACCESS_LEVEL_AFFILIATE: 101,
    SETTING_ACCESS_LEVEL_WEBSITE_VISITORS: 96,
    SETTINGS_VOUCHERS_TYPES: {
        1: "LANG_VOUCHERS_TYPE_VALUE",
        2: "LANG_VOUCHERS_TYPE_PERCENTAGE",
        3: "LANG_VOUCHERS_TYPE_1FREE12999",
        4: "LANG_VOUCHERS_TYPE_VALUE_FOR_EVERY_ITEM"
    },
    SETTINGS_USERS_SEX_ID: {
        1: "LANG_MALE",
        2: "LANG_FEMALE",
        3: "LANG_OTHER"
    },
    SETTINGS_EMAILS_TYPES: {
        0: "LANG_EMAILS_STATUS_ADDED",
        1: "LANG_EMAILS_STATUS_SENT",
        2: "LANG_EMAILS_STATUS_FAILED",
        3: "LANG_EMAILS_STATUS_RETRY"
    },
    SETTING_DELIVERED_ORDER_STATUS_ID: 5,
    API_ENDPOINT_DELIVERY_METHODS: 'art/delivery/methods',
    SETTING_SITE_URL: "https://art.lexie.xyz",
    SETTING_STATIC_SITE_URL: "https://static.lexie.xyz",
    API_ENDPOINT_RETOUR: "art/orders/retour",
    API_ENDPOINT_ADMIN_RETOURS: "art/orders/retours",
    API_ENDPOINT_GDPR_REQUESTS: "art/gdpr/requests",
    API_ENDPOINT_CONTACT_MESSAGES: "art/contact/messages",
    API_ENDPOINT_ALBUMS_CURRENT_USER: "art/albums/current/user",
    API_ENDPOINT_ALBUMS: "art/albums",
    API_ENDPOINT_USER_ALBUMS_PAGINATED:"art/albums//paginated/",
    API_ENDPOINT_ALBUMS_INFO: "art/albums/info",
    API_ENDPOINT_ALBUMS_CURRENT_USER_ALL: "art/albums/current/user/all",
    API_ENDPOINT_ALBUMS_BY_IMAGE: "art/albums/by/image/",
    API_ENDPOINT_ALBUMS_IMAGES: "art/albums/images",
    API_ENDPOINT_ALBUMS_IMAGES_WITH_VARIANTS: "art/albums/images/with/variants",
    API_ENDPOINT_ALBUMS_IMAGES_OLDER: "art/albums/images/older/",
    API_ENDPOINT_ALBUMS_IMAGES_OLDER_WITH_VARIANTS: "art/albums/images/older/with/variants/",
    API_ENDPOINT_ALBUMS_IMAGES_PATH: "art/albums/image/path/",
    API_ENDPOINT_ALBUMS_SEARCH: "art/albums/search",
    API_ENDPOINT_IMAGES_STOCKS: "art/images/stocks",
    API_ENDPOINT_SEARCH_IMAGES_STOCKS: "art/images/variants/image/path",
    API_ENDPOINT_VOUCHERS: "art/vouchers",
    API_ENDPOINT_SHOPPING_CART_VOUCHER: "art/vouchers/shoppingcart",
    API_ENDPOINT_VOUCHER_VALID: "art/vouchers/valid",
    API_ENDPOINT_EMAILS: "admin/emails",
    API_ENDPOINT_USERS: "admin/users",
    API_ENDPOINT_WEBSITE_VISITORS: "website/visitors",
    API_ENDPOINT_AFFILIATES_IS_AFFILIATE: "art/affiliates/is/affiliate/",
    API_ENDPOINT_AFFILIATES: "art/affiliates",
    API_ENDPOINT_AFFILIATES_PAYMENTS: 'art/affiliates/payments',
    API_ENDPOINT_ADMIN_AFFILIATES_PAYMENTS: 'art/admin/affiliates/payments',
    API_ENDPOINT_USER_AFFILIATES_INFO: 'art/user/affiliates',
    API_ENDPOINT_VARIANTS_DIMENSIONS: 'art/variants/dimensions',
    API_ENDPOINT_COMMENTS: "art/images/comments",
    API_ENDPOINT_IMAGES_COMMENTS_BY_PATH: 'art/images/comments/by/path',
    API_ENDPOINT_PHOTOGRAPHER_IS_PHOTOGRAPHER: "art/affiliates/is/photographer/",
    API_ENDPOINT_PHOTOGRAPHER: "art/photographer",
    API_ENDPOINT_RANDOM_IMAGES: "art/random/images",
    API_ENDPOINT_RANDOM_IMAGES_NOT_LIKED: "art/random/image/not/liked",
    API_ENDPOINT_USER_RANDOM_IMAGES_WITH_VARIANTS: "art/random/user/images/",
    API_ENDPOINT_RANDOM_IMAGES_WITH_VARIANTS: "art/random/images/with/variants",
    API_ENDPOINT_ADMIN_ACCESS_LEVELS: "admin/access/levels",
    API_ENDPOINT_ADMIN_USERS_ACCESS_LEVELS: "admin/users/access/levels/",
    API_ENDPOINT_IMAGES_LIKES: "art/images/likes",
    API_ENDPOINT_IMAGES_NOT_LIKES: "art/images/not/likes",
    API_ENDPOINT_PHOTOGRAPHERS: "art/photographers",
    API_ENDPOINT_MY_FAVORITE_IMAGES: 'art/my/favorite/images/with/variants',
    API_ENDPOINT_MY_FAVORITE_IMAGES_OLDER: 'art/my/favorite/images/with/variants/older/',
    API_ENDPOINT_USERS_PROFILE_SETTINGS: "users/profile/settings",
    API_ENDPOINT_USERS_PROFILE_PICTURE: "users/profile/picture",
    API_ENDPOINT_IMAGES_PROMOTED: "art/images/promoted",
    URL_STOCKS: "stocks/",
    URL_RAW_IMAGES: "/my/raw/pictures",
    API_ENDPOINT_PATH_RAW_THUMB: "file/thumb/png",
    API_ENDPOINT_DOWNLOAD_RAW_IMAGE: "art/file/download/raw/image",
    API_ENDPOINT_RAW_IMAGES_DISTINCT_DESCRIPTIONS: "art/raw/distinct/descriptions"
}

export default settings;