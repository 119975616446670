import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Settings from '../../../Settings/Settings'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Style from '../Styles/SearchYourImagesStyle';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import $ from "jquery";
import LoadingComponent from '../../LoadingComponent/LoadingComponent';
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Paper } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VideoCallIcon from '@material-ui/icons/VideoCall';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function AddVideo(props) {
    const classes = useStyles();
    const [imagesArray, setImagesArray] = React.useState([])
    const [filesArray, setFilesArray] = React.useState([])
    const [uploadingImages, setUploadingImages] = React.useState(false)
    const [existingImages, setExistingImages] = React.useState([])
    const [confirmDeleteImage, setConfirmDeleteImage] = React.useState({})
    const [locationNames, setLocationNames] = React.useState([]);
    const [imagesPublished, setImagesPublished] = React.useState([]);
    const [descriptions, setDescriptions] = React.useState([])
    const [uploadingFileIndex, setUploadingFileIndex] = React.useState(null)
    const [countUploadingFiles, setCountUploadingFiles] = React.useState(0)
    const [seed, setSeed] = React.useState(1);

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
      }
    
      const componentProps = {
          ...props.componentProps
      }
      const [state, setState] = React.useState({
        imagesPublished: true,
      });
    const setUploadImagesLocationName = (event, idx) => {
        let array = locationNames
        array[idx] = event.target.value
        setLocationNames(array)
        setSeed(Math.random() + Math.random());
    }
    const setUploadImagesDescription = (event, idx) => {
        let array = descriptions
        array[idx] = event.target.value
        setDescriptions(array)
        setSeed(Math.random() + Math.random());
    }

    const clearImages = () => {
        setFilesArray([])
        setImagesArray([])
    }
    const addImage = () => {
        let input = document.createElement('input')
        input.type = 'file';
        input.multiple = 'multiple'
        input.accept="video/*";
        input.click();
        input.onchange = () => {
            let imgArr = [...imagesArray];
            let fileArr = [...filesArray];
            for (let i =0;i <input.files.length; i++){
                let url = URL.createObjectURL(input.files[i])
                imgArr.push(url);
            }
            fileArr.push(input);
            setFilesArray(fileArr)
            setImagesArray(imgArr)
        }
    }
    const handleChangeImagesPublished = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
      };
    
    const postImages = (index, fileIndex = 0, detailsIndex = 0) => {
        window.location.hash = '';
        window.location.hash = 'mainContainer';
        setUploadingFileIndex(detailsIndex)
        setUploadingImages(true)
        const formData = new FormData();

        if (filesArray[index].files[fileIndex]) {
            formData.append('image' + index + fileIndex, filesArray[index].files[fileIndex]);
            formData.append("videosLocationName", locationNames[detailsIndex]?locationNames[detailsIndex]:"")
            formData.append("videosDescription", descriptions[detailsIndex]?descriptions[detailsIndex]:"")
            formData.append("videosPublished", state.imagesPublished? "1": "0")    
            if(componentMethods.getLocalStorage('user') && componentMethods.getLocalStorage('user').tokensHash) {
                $.ajax({
                    url: Settings.API_URL + Settings.API_ENDPOINT_UPLOAD_VIDEOS,
                    type: 'POST',
                    data: formData,
                    headers:{
                        "X-Account-Token": componentMethods.getLocalStorage("user").tokensHash
                    },
                    success: function (data) {
                        let fileIndexRequest = fileIndex+1
                        let indexRequest = index
                        detailsIndex++
                        if (fileIndexRequest > filesArray[index].files.length - 1) {
                            indexRequest = index + 1 
                            fileIndexRequest = 0
                        }
                        if (!filesArray[indexRequest])
                        {
                            setUploadingImages(false)
                            setFilesArray([])
                            setImagesArray([])
                            setDescriptions([])
                            setLocationNames([])
                        } else {

                            postImages(indexRequest, fileIndexRequest, detailsIndex)
                        }
                    },
                    error: function (request, status, error) {
                        setUploadingImages(false)
                        setFilesArray([])
                        setImagesArray([])
                        componentMethods.addError(componentConstants.language["LANG_ERROR_COULD_NOT_LOAD_IMAGE"])
                    },
                    cache: false,
                    contentType: false,
                    processData: false
                });
            }
        }
    }

  return (<div className={classes.rootAddImage}><React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12} md={12} lg={12}>
                    {componentConstants.language.LANG_NEW_IMAGES}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {uploadingImages ?
                        <React.Fragment>
                            <LoadingComponent /> 
                            <Typography id="uploading-images" variant="subtitle1" component="p" style={{textAlign:"center"}}>{componentConstants.language.LANG_IMAGES_UPLOADING}{uploadingFileIndex+1}/{imagesArray.length}</Typography>
                        </React.Fragment>
                    :
                    
                        <React.Fragment>
                            <Button fullWidth color="secondary" onClick={() => {addImage()} } style={{height: 150, width:150}} ><VideoCallIcon /></Button>
                            {
                                imagesArray.map((item, index) => {
                                    return <React.Fragment key={index}>
                                                <div className={classes.imgContainer}>
                                                    <Paper style={{margin: 8, padding: 8}}>
                                                        <video controls src={item} className={classes.img} />
                                                        <TextField name={'imagesLocationName'} value={locationNames[index]} error={componentMethods.getManagerState().formInvalidUploadImagesLocationName} className={classes.formFullWidth} onChange={(event) => {setUploadImagesLocationName(event, index)}} id="UPLOAD_IMAGES_LOCATION_NAME" label={componentConstants.language.LANG_UPLOAD_IMAGE_LOCATION_NAME} fullWidth={true} />
                                                        <TextField name={'imagesDescription'} value={descriptions[index]} error={componentMethods.getManagerState().formInvalidUploadImagesDescription} className={classes.formFullWidth} onChange={(event) => {setUploadImagesDescription(event, index)}} id="UPLOAD_IMAGES_DESCRIPTION" label={componentConstants.language.LANG_UPLOAD_IMAGE_DESCRIPTION} fullWidth={true} />
                                                    </Paper>
                                                </div>
                                            </React.Fragment>
                                })
                            }
                            {imagesArray.length > 0?
                                <React.Fragment>
                                    <div>
                                        <FormControlLabel fullWidth={true} className={classes.formFullWidth}
                                            control={<Checkbox value={1} checked={state.imagesPublished} onChange={handleChangeImagesPublished} name="videosPublished" />}
                                            label={componentConstants.language["LANG_IMAGES_PUBLISHED"]}
                                        />
                                    </div>
                                    <Button style={{marginBottom: 8}} fullWidth color="secondary" variant="outlined" onClick={() => {clearImages()} } >{componentConstants.language.LANG_CANCEL_VIDEOS}</Button>
                                    <Button fullWidth color="secondary" variant="contained" onClick={() => {postImages(0)} }><PublishIcon /> {componentConstants.language.LANG_UPLOAD_VIDEOS}</Button>
                                </React.Fragment>
                            :
                                null
                            }
                            
                        </React.Fragment>
                    }
                </Grid>
                </Grid>
            </React.Fragment>
            </div>
  );
}